import React, { FC } from "react";
import styled from "styled-components";

const WhiteColoredPath = styled.path`
  fill: #ffffff;
`;

const CurrentColorPath = styled.path`
  fill: currentColor;
`;

type Props = {
  size?: string | number;
};

const MercurialIcon: FC<Props> = ({ size }) => (
  <svg
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    x="0px"
    y="0px"
    width={size}
    height={size}
    viewBox="0 0 16 16"
  >
    <g id="Layer_1">
      <g>
        <g id="_x33_e91140ac1bfb9903b91c1b0ca096a4c">
          <CurrentColorPath
            d="M6.9,11.1c0.2,0.6,0,1.2-0.3,1.7c-0.3,0.5-0.7,0.9-1.4,1.1c-1.1,0.3-2.3-0.4-2.6-1.5c-0.2-0.6,0-1.2,0.3-1.7
				c0.3-0.5,0.7-0.9,1.4-1.1C5.4,9.3,6.6,10,6.9,11.1z M3.4,8.1c0.3-0.4,0.4-1,0.3-1.3c-0.2-0.6-0.8-1.1-1.5-1
				c-0.5,0-0.9,0.4-1.1,0.8C0.9,6.8,0.9,7.1,0.9,7.5C1,8.2,1.6,8.8,2.4,8.7C2.8,8.7,3.2,8.4,3.4,8.1z M7.8,1.1c-1.8,0.2-2.9,1-3.4,2
				C3.3,4.9,4.2,7.5,7.1,8.3c3.7,1.1,0.8,3.4,0.9,5c0.1,1.6,3.3,2.2,5.7-2c0-0.1,0.1-0.2,0.1-0.2C16.2,7,13.2,0.4,7.8,1.1z"
          />
        </g>
      </g>
    </g>
    <g id="Layer_2">
      <WhiteColoredPath
        d="M13.9,7.5c0,0-0.1,0-0.2,0.5c-0.2,0.5-0.5,1.7-0.8,2.2c-0.5,0.9-1.2,1.2-1.4,1.3c-0.2,0.1-0.4,0-0.5,0
		c-0.4,0-0.9,0.2-1.2,0.8s-0.6,1.1-0.5,1.2c0.1,0.2,1,0.1,1.5-0.2c0.5-0.3,1.2-0.9,1.7-1.6c0.5-0.7,1.1-2.3,1.3-3
		C14.3,7.4,14.1,7.2,13.9,7.5z"
      />
      <WhiteColoredPath
        d="M9.1,8.9c0,0,0.3,0,0.1-0.6c-0.1-0.4-0.8-0.7-1.9-1C6.8,7.2,5.9,6.8,5.4,6.4C4.7,5.8,4.4,4.7,4.4,4.7
		S4.2,4.7,4.2,4.8c0,0.2,0.5,1.3,1.2,2c0.8,0.7,0.8,0.7,1.5,0.9c0.8,0.3,1,0.4,1.3,0.5C8.6,8.4,8.7,8.6,9.1,8.9z"
      />
      <WhiteColoredPath d="M3.2,7.9c0,0,0.2-0.4,0-0.5C3,7.2,2.6,7.2,2.4,7.4C2.3,7.6,2.2,7.9,2.3,8.1C2.4,8.3,2.7,8.4,3.2,7.9z" />
      <WhiteColoredPath
        d="M6.3,12.4c0.1-0.5,0-0.7-0.2-0.7C6,11.7,6,11.8,5.9,12s-0.2,0.5-0.5,0.7c-0.2,0.1-0.4,0.1-0.6,0.3
		s-0.3,0.2-0.2,0.4c0.1,0.1,0.4,0.2,0.7,0.1C5.5,13.4,6.2,12.9,6.3,12.4z"
      />
    </g>
  </svg>
);

export default MercurialIcon;
